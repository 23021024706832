<template>
  <div id="informationFeedback">
    <div class="goBack">
      <i class="goBackClose" @click="jumpBack"></i>
    </div>
    <div class="feedbackTitle">订单信息反馈</div>
    <div class="feedbackContent">
      <div class="contentTitle">反馈内容<span>*</span></div>
      <textarea
        v-model="inputFeedback"
        class="inputFeedback"
        maxlength="100"
        placeholder="填写反馈内容，最多100字"
      ></textarea>
    </div>
    <div class="uploadPictures">
      <div class="uploadTitle">上传图片</div>
      <div class="uploadBox">
        <ul>
          <li class="uploadImg" v-for="(imgs, index) in exImgs" :key="index">
            <img
              class="deleteImg"
              src="../assets/images/pending/delete.png"
              alt=""
              @click="deleteImg(index)"
            />
            <img
              class="showImg"
              :src="imgs"
              alt=""
              @click="bigImg(exImgs, index)"
            />
          </li>
          <li class="addButton" @click="isClickUpload" v-show="isShow">
            <img src="../assets/images/pending/addImage.png" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="feedbackConfirm" @click="confirmUp">确认反馈</div>
    <van-overlay :show="show">
      <van-loading size="24px" vertical>上传中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { dialog } from "../utils/vant.js";
import { ImagePreview } from "vant";
import { feedbackOrderEngineerEx } from "../api/home";
import { getCalls } from "../utils/upload";
export default {
  data() {
    return {
      userId: this.$route.query.userId, //用户id
      orderNo: this.$route.query.orderNo, //订单id
      inputFeedback: "", //反馈内容
      exImgs: [],
      isShow: true,
      show: false,
    };
  },
  mounted() {
    window.getUpdateImageUrl_call = (res) => {
      this.getUpdateImageUrl_call(res);
    };
  },
  methods: {
    isClickUpload() {
      getCalls(this.exImgs);
    },
    //图片上传后的回调
    getUpdateImageUrl_call(res) {
      let imgs = JSON.parse(res);
      imgs.map((e) => {
        this.exImgs.push(e.filePath);
      });
      if (this.exImgs.length >= 10) {
        this.isShow = false;
      }
    },
    //图片预览
    bigImg(imgs, index) {
      ImagePreview({
        images: imgs, // 预览图片的那个数组
        showIndex: true,
        startPosition: index, // 指明预览第几张图
      });
    },
    //删除图片
    deleteImg(index) {
      this.exImgs.splice(index, 1);
      this.isShow = true;
    },
    // 信息反馈确认提交
    confirmUp() {
      let _this = this;
      if (_this.inputFeedback == "") {
        _this.$dialog.alert({
          message: "信息反馈内容不能为空！",
        });
        return;
      }
      this.show = true;
      let params = {
        userId: _this.userId,
        orderNo: _this.orderNo,
        exDesc: _this.inputFeedback,
        exImgs: _this.exImgs + "",
      };
      feedbackOrderEngineerEx(params).then((res) => {
        this.show = false;
        if (res.status) {
          _this.$dialog
            .alert({
              message: "信息反馈成功！",
            })
            .then(() => {
              window.history.go(-1);
            });
        } else {
          _this.$dialog.alert({
            message: "信息反馈异常，请稍后再试！",
          });
        }
      });
    },

    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
  },
};
</script>

<style lang='less' scoped>
#informationFeedback {
  text-align: left;

  .goBack {
    width: 750px;
    height: 76px;
    background: #ffffff;

    .goBackClose {
      display: inline-block;
      width: 31px;
      height: 31px;
      margin: 23px 0 0 25px;
      background: url("../assets/images/pending/goBackClose.png") no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }

  .feedbackTitle {
    font-size: 54px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111111;
    line-height: 54px;
    margin: 25px 0 0 29px;
  }

  .feedbackContent {
    margin: 50px 0 0 29px;

    .contentTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;

      span {
        font-size: 26px;
        color: #ff7000;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .inputFeedback {
      width: 690px;
      height: 248px;
      padding: 20px;
      background: #ffffff;
      border: 1PX solid #dadada;
      border-radius: 7px;
      margin-top: 30px;
      resize: none;
      outline: none;
      box-sizing: border-box;
      font-size: 26px;
    }
  }

  .uploadPictures {
    margin: 49px 0 0 29px;

    .uploadTitle {
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #111111;
      line-height: 30px;
    }
    .uploadBox {
      width: 690px;
      background: #ffffff;
      border: 1PX solid #dadada;
      border-radius: 7px;
      margin-top: 31px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 110px;
          height: 110px;
          margin: 10px 14px;
        }
        .uploadImg {
          position: relative;
          .showImg {
            width: 100%;
            height: 100%;
          }
          .deleteImg {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 32px;
            height: 32px;
          }
        }
        .addButton {
          background: #F5F5F5;
          display: flex;
          img {
            margin: auto;
            width: 37px;
            height: 59px;
          }
        }
      }
    }
  }

  .feedbackConfirm {
    width: 690px;
    height: 80px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fafafa;
    text-align: center;
    line-height: 80px;
    background: #ff7000;
    border-radius: 8px;
    margin: 100px auto;
    cursor: pointer;
  }
}
</style>